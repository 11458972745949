// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/PilihJalur.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#report_register[data-v-dd4a49be]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:auto;background-repeat:no-repeat;background-position:50%;opacity:.5}.demo-wrap[data-v-dd4a49be]{position:relative;height:100vh}@media screen and (max-width:576px){.demo-wrap[data-v-dd4a49be]:before{content:\" \";display:block;position:absolute;left:0;top:0;width:100%;height:100%;opacity:.4;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:auto}.fs-flexible[data-v-dd4a49be]{font-size:1rem!important}}@media screen and (min-width:576px){.demo-wrap[data-v-dd4a49be]:before{content:\" \";display:block;position:absolute;left:0;top:0;width:100%;height:100%;opacity:.4;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100%;background-size:auto}.fs-flexible[data-v-dd4a49be]{font-size:1.25rem!important}}.demo-content[data-v-dd4a49be]{position:relative}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
