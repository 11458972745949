<template>
    <div id="pdf-content" class="px-5 pdf-content container " v-bind:class="{ 'poppins': !isButtonHidden }"
        ref="pdf_content">
        <!-- <img src="@/assets/img/login_logo.png" alt="" class="position-absolute start-0" width="130">
        <img src="@/assets/img/logo_login.png" alt="" class="position-absolute end-0" width="170"> -->
        <div class="position-relative pt-3">
            <div class="position-absolute end-0 pt-3 ">
                <button class="btn btn-danger" v-bind:class="{ 'd-none': isButtonHidden }" @click="savePDF();"
                    style="width: 150px;">
                    Simpan sebagai PDF
                </button>
            </div>
        </div>
        <div class="">
            <img src="@/assets/img/logo_navbar.png" alt="" style="max-height: 50px" />
        </div>
        <!-- <div class="fs-4 text-center mb-3 fw-bold">dr. Sofyan Tan</div> -->
        <div class="fs-4 text-start mb-2 fw-bold pt-3">Invoice</div>

        <div class="row ps-2">
            <div class="col-10 ">
                <table>
                    <tr>
                        <td style="width: 45%" class="p-0">Nomor</td>
                        <td style="width: 5%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordsPDF.nomor_invoice }}
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 45%" class="p-0">Tanggal</td>
                        <td style="width: 5%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordsPDF.tanggal }}
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 45%" class="p-0">No Ktp</td>
                        <td style="width: 5%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordMahasiswa.no_ktp }}
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 45%" class="p-0">Nama</td>
                        <td style="width: 5%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordMahasiswa.nama_lengkap }}
                        </td>
                    </tr>
                </table>


            </div>

        </div>


        <div class="row ps-2 pt-4">
            <div class="col-12">
                <table class="table ">
                    <thead>
                        <tr>
                            <th class="fw-bold " style="width: 25%;">
                                Nama Item
                            </th>
                            <th class="fw-bold " style="width: 25%;">
                                Jumlah
                            </th>
                            <th class="fw-bold " style="width: 25%;">
                                Harga
                            </th>
                            <th class="fw-bold " style="width: 25%;">
                                Sub Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr>
                            <td>{{ recordsPDF.item }}</td>
                            <td>1</td>
                            <td>{{ recordsPDF.quantity }} </td>
                            <td>{{ rupiah(recordsPDF.subtotal) }} </td>
                        </tr> -->
                        <tr v-for="(record) in recordInvoiceDetail" :key="record.id">
                            <td>{{ record.item.charAt(0).toUpperCase() + record.item.slice(1) }}</td>
                            <td>{{ record.qty }}</td>
                            <td>{{ rupiah(record.price) }}</td>
                            <td>{{ rupiah(record.price) }}</td>
                        </tr>
                        <tr v-if="recordsPDF.kode_diskon != ''">
                            <td>DISKON</td>
                            <td></td>
                            <td></td>
                            <td>-{{ rupiah(recordsPDF.diskon) }}
                                <button class="btn" @click="undo_voucher()" v-if="recordsPDF.status_text == 'PENDING'">
                                    <i class="ri-delete-bin-line text-danger"></i>
                                </button>

                            </td>
                        </tr>
                        <tr v-if="recordsPDF.total_diskon_penambahan > 0">
                            <td>Diskon Tambahan</td>
                            <td></td>
                            <td></td>
                            <td>-{{ rupiah(recordsPDF.total_diskon_penambahan) }}
                                <!-- <button class="btn" @click="undo_voucher()" v-if="recordsPDF.status_text == 'PENDING'">
                                    <i class="ri-delete-bin-line text-danger"></i>
                                </button> -->

                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td class="fw-bold">Sub Total</td>
                            <td></td>
                            <td></td>
                            <td class="fw-bold">{{ rupiah(recordsPDF.total) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row pt-4">
            <div class="col-6">
                <table class="mt-4">
                    <tr class=""
                        v-if="recordsPDF.status_text != 'SUCCESS' && (recordsPDF.kode_diskon == '' || recordsPDF.reference == '')">
                        <div>
                            <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#reedem_voucher"
                                style="width: 150px;">Reedem
                                Voucher</button>
                        </div>
                    </tr>
                </table>
            </div>

            <div class="col-6 d-flex justify-content-end">
                <div class="d-flex align-items-end">
                    <div class="me-3">
                        <div>
                            Status
                        </div>
                        <div v-if="recordsPDF.status_text == 'PENDING'" style=" width: 100%" class="p-0 fw-bold ">
                            <!-- <i class="ri-close-line"></i> -->
                            <span class="fs-6 text-danger">
                                BELUM DIBAYAR
                            </span>
                        </div>
                        <div v-else style=" width: 100%" class="p-0 fw-bold ">
                            <!-- <i class="ri-wallet-3-line"></i> -->
                            <span class="text-success fs-6">
                                LUNAS
                            </span>
                        </div>
                    </div>

                    <div class="" v-if="recordsPDF.status_text == 'PENDING'">
                        <div>
                            <button class="btn btn-danger " @click=" create_invoice(recordsPDF.nomor_invoice, voucher)"
                                style="width: 150px;">Bayar</button>
                        </div>
                    </div>
                </div>


                <!-- <table border="solid">
                    <tr>
                        <td style="width: 100%" class="p-0">Status </td>
                    </tr>
                    <tr>


                    </tr>

                </table> -->

            </div>
        </div>

        <div class="modal fade" id="reedem_voucher" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered ">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="label_data_karyawan">
                            Redeem Voucher <span class="text-capitalize"></span>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal"></button>
                    </div>
                    <form @submit.prevent="reedem_voucher">
                        <div class="modal-body modal-body2">
                            <div class="mb-3">
                                <label for="kode" class="form-label">Kode Voucher</label>
                                <input type="text" class="form-control" v-model="voucher" placeholder="Contoh : KD001"
                                    required />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Batal
                            </button>
                            <button type="submit" class="btn btn-danger">Reedem</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</template>

<style scoped>
.order-1 {
    order: 1;

}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

#swal2-html-container {
    margin: 100px !important;
}
</style>

<script>

import {
    base_url_header,
    AlertPopup,
    ShowLoading,
    CloseLoading,
    ErrorConnectionTimeOut, rupiah, base_url, AlertPopupEror, AlertPopupConfirm
    // base_url_header, ErrorConnectionTimeOut
} from "@/assets/javascript/Function";

import Cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            isButtonHidden: false,

            url2: "st_mahasiswa_detail",
            id_mahasiswa: "",
            kode_unik: "",

            nomor_invoice: "",
            voucher: "",

            recordMahasiswa: {
                nama_lengkap: "",
                no_ktp: "",
            },
            recordsPDF: {
                //biodata
                nomor_invoice: "",
                tanggal: "",
                item: "",
                subtotal: "",
                total: "",
                price: "",
                quantity: "",
            },

            recordInvoiceDetail: {

            },
        };
    },

    methods: {
        rupiah,
        generatePDF(id) {
            ShowLoading();
            let formData = new FormData();
            formData.append("url", this.url2);
            formData.append("id", id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url_header + this.url2;
            } else {
                this.api = base_url_header + this.url2;
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsPDF = response.data.data[0];

                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generatePDF2() {
            // Hide any elements that you don't want to include in the PDF
            document.querySelectorAll('.no-print').forEach(el => el.style.display = 'none');

            // Wait for all images to load before printing
            const images = document.querySelectorAll('img');
            const numImages = images.length;
            let numLoaded = 0;

            images.forEach(img => {
                if (img.complete) {
                    numLoaded++;
                } else {
                    img.addEventListener('load', () => {
                        numLoaded++;
                        if (numLoaded === numImages) {
                            // this.print();
                        }
                    });
                    img.addEventListener('error', () => {
                        numLoaded++;
                        if (numLoaded === numImages) {
                            // this.print();
                        }
                    });
                }
            });

            if (numLoaded === numImages) {
                this.print();
            }
        },
        print() {
            // Set the page size to A4
            const style = document.createElement('style');
            style.textContent = `@media print { @page { size: A4; } }`;
            document.head.appendChild(style);

            // Wait for any dynamic content to load before printing
            setTimeout(() => {
                window.print();

                // Show any hidden elements after printing
                document.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');

                // Remove the A4 page size style
                document.head.removeChild(style);
                // window.close();
                this.isButtonHidden = false;
            }, 1000);

        },
        savePDF() {
            this.isButtonHidden = true;
            this.print();
        },
        verifikasi(nomor_invoice) {
            ShowLoading();
            const formData = new FormData();

            formData.append("url", "invoice_index");
            formData.append("nomor_invoice", nomor_invoice);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "invoice_index";
            } else {
                this.api = base_url + "invoice_index";
            }

            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";
            const modifiedString = url.toString();

            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsPDF = response.data.data[0];
                        this.recordMahasiswa = response.data.data_mahasiswa[0];
                        this.recordInvoiceDetail = response.data.data_invoice;

                        if (response.data.data[0].reference != '' && response.data.data[0].status_text == 'PENDING') {
                            // this.api_create_invoice_bni(response.data.data[0].nomor_invoice, modifiedString, 'inquirybilling');
                            window.location.href = modifiedString + "cara_pembayaran?nomor_invoice=" + response.data.data[0].nomor_invoice;
                        }
                        // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });
        },
        create_invoice(nomor_invoice) {
            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";

            const modifiedString = url.toString();

            let callback = "";

            Swal.fire({
                icon: "info",
                text: "Apakah Anda Yakin? Setelah menekan tombol ini anda harus langsung membayar dengan tenggat waktu yang diberikan",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Ya, lanjut proses',
                denyButtonText: `Tidak, tetap di halaman ini`,
                denyButtonColor: '#efefef',
                confirmButtonColor: '#dc3545',
                width: '800px',
                heightAuto: false,
                customClass: {
                    denyButton: 'order-1',
                    confirmButton: 'order-2',

                },
                didOpen: () => {

                    const denyButton = Swal.getDenyButton();
                    denyButton.style.color = 'black'; // Set your desired text color here
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.recordsPDF.reference == "") {
                        callback = "createbilling";
                    } else {
                        callback = "inquirybilling";
                    }
                    this.api_create_invoice_bni(nomor_invoice, modifiedString, callback);

                }
            });


        },
        api_create_invoice_bni(nomor_invoice, modifiedString, callback) {
            ShowLoading();
            const formData = new FormData();

            formData.append("url", "create_invoice_bni");
            formData.append("kode", nomor_invoice);
            formData.append("slug", "ustb");
            formData.append("callback", callback);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // formData.append("callback", "https://pmb.satyaterrabhinneka.ac.id/invoice_view?nomor_invoice=" + nomor_invoice);

            if (process.env.NODE_ENV === "development") {
                console.log('test');
                this.api = base_url + "create_invoice_bni_demo";
            } else {
                this.api = base_url + "create_invoice_bni";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {
                        // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);
                        CloseLoading();
                        localStorage.setItem('tanggal_terakhir_pembayaran', response.data.payment_time);
                        window.location.href = modifiedString + "cara_pembayaran?nomor_invoice=" + this.recordsPDF.nomor_invoice;

                    }
                    if (response.data.status == false) {
                        if (response.data.data != undefined) {
                            localStorage.setItem('tanggal_terakhir_pembayaran', response.data.payment_time);
                            window.location.href = modifiedString + "cara_pembayaran?nomor_invoice=" + this.recordsPDF.nomor_invoice;
                        } else {
                            AlertPopupEror('', response.data.message)
                        }


                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        api_create_invoice_cash(nomor_invoice, modifiedString, callback) {
            ShowLoading();
            const formData = new FormData();

            formData.append("url", "create_invoice_bni");
            formData.append("kode", nomor_invoice);
            formData.append("slug", "ustb");
            formData.append("callback", callback);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "create_invoice_cash";
            } else {
                this.api = base_url + "create_invoice_cash";
            }
            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {
                    if (response.data.status == true) {
                        // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);
                        CloseLoading();
                        AlertPopupConfirm('success', '', response.data.message);
                        // localStorage.setItem('tanggal_terakhir_pembayaran', response.data.payment_time);
                        // window.location.href = modifiedString + "cara_pembayaran?nomor_invoice=" + this.recordsPDF.nomor_invoice;

                    }
                    if (response.data.status == false) {
                        if (response.data.data != undefined) {
                            // localStorage.setItem('tanggal_terakhir_pembayaran', response.data.payment_time);
                            // window.location.href = modifiedString + "cara_pembayaran?nomor_invoice=" + this.recordsPDF.nomor_invoice;

                        } else {
                            AlertPopupEror('error', response.data.message,)
                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        SettingCaptchaView() {

            if (this.isMobile) {
                this.Captcha = "hidden"
            }

            const badgeElement = document.querySelector('.grecaptcha-badge');
            if (this)
                if (badgeElement) {

                    badgeElement.style.visibility = this.Captcha;
                    badgeElement.style.top = '150px';
                } else {
                    // Create a new MutationObserver
                    const observer = new MutationObserver(() => {
                        // When the reCAPTCHA badge element appears, hide it
                        const badgeElement = document.querySelector('.grecaptcha-badge');
                        if (badgeElement) {

                            badgeElement.style.visibility = this.Captcha;
                            badgeElement.style.top = '150px';

                            // Disconnect the observer once the badge element has been hidden
                            observer.disconnect();
                        }
                    });

                    // Start observing changes to the DOM
                    observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });
                }
        },
        reedem_voucher() {
            ShowLoading();
            const formData = new FormData();
            formData.append("url", "reedem_voucher");
            formData.append("nomor_invoice", this.recordsPDF.nomor_invoice);
            formData.append("kode_diskon", this.voucher);
            formData.append("slug", "ustb");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "reedem_voucher";
            } else {
                this.api = base_url + "reedem_voucher";
            }
            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertPopup("success", response.data.message, "", 1500, false);
                        this.$refs.close_modal.click();
                        this.recordsPDF = response.data.data;
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });
        },
        undo_voucher() {
            ShowLoading();
            const formData = new FormData();
            formData.append("url", "undo_voucher");
            formData.append("nomor_invoice", this.recordsPDF.nomor_invoice);
            formData.append("kode_diskon", this.recordsPDF.kode_diskon);
            formData.append("slug", "ustb");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "undo_voucher";
            } else {
                this.api = base_url + "undo_voucher";
            }
            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {

                    if (response.data.status == true) {
                        AlertPopup("success", response.data.message, "", 1500, false);
                        this.recordsPDF = response.data.data;
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });
        },
        resizeHandler() {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 1024) {
                document.body.style.width = "576px";
            } else {
                document.body.style.width = "100%";
            }
        },


    },
    mounted() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        const str = this.$route.fullPath;
        const slug = str.split('nomor_invoice=').pop();
        const slug2 = slug.split('&')[0];
        this.verifikasi(slug2);


        window.addEventListener("resize", this.resizeHandler);
        this.resizeHandler();

        const userAgent = navigator.userAgent.toLowerCase();
        this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

        this.SettingCaptchaView();


    },
};
</script>