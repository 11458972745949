<template>
  <NavbarDefault />
  <section id="form_pendaftaran">
    <div class="mt-5 pt-5"></div>
    <div class="text-center mb-3 text-danger fw-bold fs-4">
      Formulir Pendaftaran Jalur Mandiri
    </div>
    <div class="container" v-on:contextmenu.prevent>
      <form @submit.prevent="recaptcha" @keydown.enter.prevent>
        <div v-show="this.page_number == 1">
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Data Pribadi
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Lengkap Sesuai Rapor atau Ijazah
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_lengkap')" v-model="currentRecord.nama_lengkap"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_lengkap') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_lengkap')">{{
                  inputTextFeedback }}
                </div>
                <div class="text-secondary">
                  Nama yang diisikan adalah nama yang sesuai dokumen KTP/Kartu
                  Keluarga, Pengisian nama harus menggunakan huruf(tidak boleh ada
                  angka)
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Jenis Kelamin <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="JenisKelamin" id="Pria" autocomplete="off"
                    v-bind:checked="selectedOption2 === 'Pria'" v-on:change="updateSelectedOption2('Pria')"
                    :disabled="isPriaDisabled" />
                  <label class="btn btn-outline-secondary" for="Pria"><i class="fa fa-window-close"
                      aria-hidden="true"></i>Pria</label>
                  <input type="radio" class="btn-check" name="JenisKelamin" id="Wanita" autocomplete="off"
                    v-bind:checked="selectedOption2 === 'Wanita'" v-on:change="updateSelectedOption2('Wanita')" />
                  <label class="btn btn-outline-secondary" for="Wanita">Wanita</label>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="no_handphone" class="form-label">
                  No Handphone <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="no_handphone" class="form-control" id="no_handphone"
                  v-model="currentRecord.whatsapp" v-bind:class="{ 'is-invalid': !isInputTextValid('whatsapp') }"
                  @keypress="validateInput_number_only" @change="validateInput('whatsapp')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('whatsapp')">{{
                  inputTextFeedback_whatsapp }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Email <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="email" class="form-control" id="email" v-model="currentRecord.email"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('email') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('email')">{{
                  inputTextFeedback_email }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="kodepos" class="form-label">
                  Kode Daftar <span class="text-danger"></span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="admin" class="form-control" id="admin" v-model="currentRecord.admin"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('admin') }" />
                <!-- <v-select v-model="currentRecord.admin" :options="adminList" placeholder="Pilih Marketing"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('admin') }" ref="admin"
                  class="form-control custom-select"></v-select> -->
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('admin')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <!-- <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Depan
                  <span class="text-danger">*</span>
                </label>
              </div> -->
              <!-- <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_depan')" v-model="currentRecord.nama_depan"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_depan') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_depan')">{{
                  inputTextFeedback }}
                </div>

              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Tengah
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_tengah')" v-model="currentRecord.nama_tengah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_tengah') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_tengah')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="nis" class="form-label">
                  Nama Belakang
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nis" class="form-control" id="nis"
                  @input="handleInputNama($event, 'nama_belakang')" v-model="currentRecord.nama_belakang"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_belakang') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_belakang')">{{
                  inputTextFeedback }}
                </div>
              </div>
              
              
              
              <div class="col-12 col-md-3">
                <label for="kodepos" class="form-label">
                  Nama Marketing <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <v-select v-model="currentRecord.admin" :options="adminList" placeholder="Pilih Marketing"
                  :searchable="true" :class="{ 'is-invalid': !isInputTextValid('admin') }" ref="admin"
                  class="form-control custom-select"></v-select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('admin')">{{
                  inputTextFeedback }}
                </div>
              </div> -->
            </div>
          </div>
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Sekolah Asal
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="nama_sekolah" class="form-label">
                  Nama Sekolah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="nama_sekolah" class="form-control" id="nama_sekolah"
                  v-model="currentRecord.nama_sekolah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('nama_sekolah') }" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('nama_sekolah')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="jurusan_sekolah" class="form-label">
                  Jurusan Sekolah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <input type="text" name="jurusan_sekolah" class="form-control" id="jurusan_sekolah"
                  v-model="currentRecord.jurusan_sekolah"
                  v-bind:class="{ 'is-invalid': !isInputTextValid('jurusan_sekolah') }"
                  @input="handleInputNama($event, 'jurusan_sekolah')" />
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('jurusan_sekolah')">{{
                  inputTextFeedback }}
                </div>
              </div>
            </div>
          </div>
          <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
            Pilihan
          </div>
          <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
            <div class="row">
              <div class="col-12 col-md-3">
                <label for="prodi" class="form-label">
                  Program Studi yang diminati<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="prodi" id="prodi" v-model="prodi"
                  @change="onOptionSelected" v-on:change="!isInputTextValid('prodi')" v-bind:class="{
                    'is-invalid': !isInputTextValid('prodi')
                  }">>
                  <!-- v-bind:disabled="prodiList.nama === 'D3 KEBIDANAN' && isMan === true" -->
                  <option v-for="prodiList in prodiList" :key="prodiList.nama" :value="prodiList.nama" disabled>
                    {{ prodiList.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('prodi')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label for="waktu_kuliah" class="form-label">
                  Waktu Kuliah<span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <select class="form-select" aria-label="Default select example" name="waktu_kuliah" id="waktu_kuliah"
                  v-model="currentRecord.waktu_kuliah" @change="checkInputValidity('waktu_kuliah')" v-bind:class="{
                    'is-invalid': !isInputTextValid('waktu_kuliah')
                  }">
                  <option value="" selected disabled>Pilih
                    waktu kuliah </option>
                  <option v-for="waktu_kuliah in waktuKuliahList" :key="waktu_kuliah.nama" :value="waktu_kuliah.nama">
                    {{ waktu_kuliah.nama }}
                  </option>
                </select>
                <div id="input-text-feedback" class="invalid-feedback" v-if="!isInputTextValid('waktu_kuliah')">{{
                  inputTextFeedback }}
                </div>
              </div>
              <!-- 
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault">
                  Ya, saya setuju bahwa seluruh data yang saya isikan dan/atau unggah adalah benar, sah, legal dan/atau
                  sesuai dengan keadaan dan/atau kenyataan.<span class="fw-bold"> SAYA TIDAK AKAN MENGUBAH SETELAH AKUN
                    DIBUAT</span>
                </label>
              </div>
               -->
              <div class="text-end">
                <button ref="myButton" @click="checkValidation()" hidden>Click me</button>
                <button type="submit" class="btn btn-primary">Register</button>
              </div>
            </div>
          </div>

        </div>
        <hr v-if="isMobile">
      </form>
    </div>
  </section>
</template>

<style></style>

<script>
// @ is an alias to /src
import axios from "axios";
import Cookies from "js-cookie";
import { base_url, goPage, ShowLoading, AlertPopup, ErrorConnectionTimeOut, base_url_wilayah_indonesia, DateNow, rupiah2, CloseLoading } from "@/assets/javascript/Function";
import NavbarDefault from "@/components/NavbarDefault.vue";
import imageCompression from 'browser-image-compression';
import { useReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";
// import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Swal from "sweetalert2";



export default {

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const myButton = ref(null);
    const isButtonClickTriggered = ref(false);

    const recaptcha = async () => {
      // Only trigger the click event if it hasn't been triggered before
      if (!isButtonClickTriggered.value) {
        isButtonClickTriggered.value = true; // Set the flag to true

        try {
          // (optional) Wait until recaptcha has been loaded.
          if (process.env.NODE_ENV === "development") {
            console.log('development')
          } else {
            await recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            const token = await executeRecaptcha("login");
            // Do stuff with the received token.
            Cookies.set("token2", token);
          }
          // Trigger click event on myButton
          myButton.value.click();
        } finally {
          // Reset the flag after the function is finished, even if an error occurs
          isButtonClickTriggered.value = false;
        }
      }

    };

    return {
      recaptcha,
      myButton,
    };
  },
  data() {
    return {
      isiLainnyaPekerjaanAyah: "",
      isiLainnyaPekerjaanIbu: "",
      isLainnyaPekerjaanAyah: false,
      isLainnyaPekerjaanIbu: false,
      isWNA: false,

      tanggal_sekarang: "",
      tanggal_max_lahir: "",
      isMobile: false,
      Captcha: "visible",
      tanggal_hari_ini: "",
      tanggal_acuan_hari_ini: "",
      //
      inputTextKodePos: 'harus 5 digit',
      inputTextFeedback_ktp: 'harus 16 digit',
      inputTextFeedback_nik_ayah: 'harus 16 digit',
      inputTextFeedback_nik_ibu: 'harus 16 digit',
      inputTextFeedback_nisn: 'harus 10 digit',
      inputTextFeedback: 'Wajib diisi',
      inputTextFeedback_whatsapp: 'No Hp tidak valid',
      inputTextFeedback_email: 'Email tidak valid',
      inputFileFeedback: 'Wajib diisi',
      inputTextFeedback_validasi_ktp: 'asdas',
      ValidasiTanggal: 'Terjadi kesalahan pada tanggal, tanggal lahir maksimal ',
      name: "testing",
      selectedOption: "WNI",
      selectedOption2: "Pria",
      nikorpasspor: "No Induk Kependudukan (NIK)",
      page_number: null,

      //submit form 
      formSubmitted_1: false,
      formSubmitted_2: false,

      //foto 
      provinsi_siswa: "",
      kota_siswa: "",
      kecamatan_siswa: "",
      kelurahan_siswa: "",

      //ortu
      provinsi_ortu: "",
      kota_ortu: "",
      kecamatan_ortu: "",
      kelurahan_ortu: "",

      //sekolah 
      provinsi_sekolah: null,
      kota_sekolah: null,


      //token recaptcha
      token2: "",

      prodiList: [], // an empty array to store options
      prodi: "", // a string to store the selected option

      kotaList: [],
      provinsiList: [],
      kecamatanList: [],
      kelurahanList: [],

      kotaList_ortu: [],
      provinsiList_ortu: [],
      kecamatanList_ortu: [],
      kelurahanList_ortu: [],

      kotaList_sekolah: [],
      provinsiList_sekolah: [],
      previousProvinsi: "",
      previousKota: "",
      previousKecamatan: "",

      previousProvinsiOrtu: "",
      previousKotaOrtu: "",
      previousKecamatanOrtu: "",

      previousProvinsiSekolah: "",
      previousKotaSekolah: "",

      isMan: false,

      pendidikanTerakhirList: [
        {
          nama: "Tidak/ Belum Sekolah"
        },
        {
          nama: "Tidak Tamat SD/Sederajat"
        },
        {
          nama: "Tamat SD/Sederajat"
        },
        {
          nama: "SLTP/Sederajat"
        },
        {
          nama: "SLTA/Sederajat"
        },
        {
          nama: "Diploma I/II"
        },
        {
          nama: "Akademi/Diploma III/Sarjana Muda"
        },
        {
          nama: "Diploma IV/Strata I"
        },
        {
          nama: "Strata II"
        },
        {
          nama: "Strata III"
        }
      ],

      agamaList: [
        {
          nama: "Islam",
        },
        {
          nama: "Katolik",
        },
        {
          nama: "Protestan",
        },
        {
          nama: "Hindu",
        },
        {
          nama: "Buddha",
        },
        {
          nama: "Konghucu",
        },

      ],

      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "KARYAWAN SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ],

      ukuranJasList: [
        {
          nama: "S",
        },
        {
          nama: "M",
        },
        {
          nama: "L",
        },
        {
          nama: "XL",
        },
        {
          nama: "XXL",
        },
        // {
        //   nama: "3XL",
        // },

      ],

      waktuKuliahList: [
        {
          nama: "Siang (14.00 s.d. 17.00 WIB)"
        },
        {
          nama: "Sore (17.30 s.d. 20.30 WIB)"
        }
      ],

      //foto
      foto_pas: "",

      currentRecord: {

        isPriaDisabled: true,

        //testing
        // foto_pas: "",
        // nama_lengkap: "ttttttttttt",
        // nama_depan: "test",
        // nama_tengah: "test",
        // nama_belakang: "test",
        // nik_ayah: "2222222222222222",
        // nik_ibu: "2222222222222222",
        // nisn_sekolah: "2222222222",
        // warga_negara: "WNI",
        // no_ktp: "2222222222222222",
        // no_kk: "2222222222222222",
        // tempat_lahir: "Purwakarta",
        // tanggal_lahir: "2000-09-14",
        // jenis_kelamin: "Pria",
        // alamat_siswa: "Purwakarta",
        // kota: "Purwakarta",
        // provinsi: "",
        // kecamatan: "",
        // kelurahan: "",
        // kodepos: "41115",
        // telepon_siswa: "08820000088762",
        // whatsapp: "08820000088762",
        // jumlah_bersaudara: "1",
        // anak_ke: "1",
        // agama: "Islam",
        // hobi: "Berenang",
        // nama_ayah: "Rizki",
        // no_hp_ayah: "08820000088762",
        // pekerjaan_ayah: "KARYAWAN SWASTA",
        // pendidikan_terakhir_ayah: "Tidak/ Belum Sekolah",
        // penghasilan_ayah: "1000000",
        // nama_ibu_kandung: "Rizki",
        // no_hp_ibu: "08820000088762",
        // pekerjaan_ibu: "KARYAWAN SWASTA",
        // pendidikan_terakhir_ibu: "Tidak/ Belum Sekolah",
        // penghasilan_ibu: "2000000",
        // alamat_ortu: "Purwakarta",
        // kota_ortu: "",
        // provinsi_ortu: "",
        // kecamatan_ortu: "",
        // kelurahan_ortu: "",
        // kodepos_ortu: "41115",
        // nama_sekolah: "asd",
        // jurusan_sekolah: "test",

        foto_pas: "",
        nama_lengkap: "",
        nama_depan: "",
        nama_tengah: "",
        nama_belakang: "",
        nik_ayah: "",
        nik_ibu: "",
        nisn_sekolah: "",
        warga_negara: "WNI",
        no_ktp: "",
        no_kk: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        jenis_kelamin: "Pria",
        alamat_siswa: "",
        kota: "",
        provinsi: "",
        kecamatan: "",
        kelurahan: "",
        kodepos: "",
        telepon_siswa: "",
        whatsapp: "",
        jumlah_bersaudara: "",
        anak_ke: "",
        agama: "",
        hobi: "",
        nama_ayah: "",
        no_hp_ayah: "",
        pekerjaan_ayah: "",
        pendidikan_terakhir_ayah: "",
        penghasilan_ayah: "",
        nama_ibu_kandung: "",
        no_hp_ibu: "",
        pekerjaan_ibu: "",
        pendidikan_terakhir_ibu: "",
        penghasilan_ibu: "",
        alamat_ortu: "",
        kota_ortu: "",
        provinsi_ortu: "",
        kecamatan_ortu: "",
        kelurahan_ortu: "",
        kodepos_ortu: "",
        nama_sekolah: "",
        jurusan_sekolah: "",

        kota_sekolah: "",
        provinsi_sekolah: "",


        prodi_pt: "",
        waktu_kuliah: "",
        ukuran_jas_akademis: "",

        foto_kartu_keluarga: "",
        foto_cover_rapor: "",
        foto_data_diri_rapor: "",
        foto_rapor_kelas_xii_ganjil_genap: "",
        foto_skl_skhun: "",
        foto_ijazah: "",
        foto_ktp_siswa: "",


        //ta
        tahun_ajaran: "",

        nama_ijazah: "",
        email: "",

        slug: "ustb",
        admin: null,
      },

      adminList: [],
    };
  },
  name: "HomeView",
  components: {
    NavbarDefault,
    // 'v-select': vSelect
  },

  watch: {
    'provinsi_siswa': function () {
      if (this.provinsi_siswa == null) {
        this.currentRecord.provinsi = "";
      } else {
        this.currentRecord.provinsi = this.provinsi_siswa.nama;
        this.kabupaten_index(this.provinsi_siswa.value, 'siswa');
        this.kota_siswa = null;
        this.kecamatan_siswa = null;
        this.kelurahan_siswa = null;
      }

    },
    'kota_siswa': function () {
      if (this.kota_siswa == null) {
        this.currentRecord.kota = "";
      } else {
        this.currentRecord.kota = this.kota_siswa.nama;
        this.kecamatan_index(this.kota_siswa.value, 'siswa');
        this.kecamatan_siswa = null;
        this.kelurahan_siswa = null;
      }
    },
    'kecamatan_siswa': function () {

      if (this.kecamatan_siswa == null) {
        this.currentRecord.kecamatan = "";
      } else {

        this.currentRecord.kecamatan = this.kecamatan_siswa.nama;
        this.kelurahan_index(this.kecamatan_siswa.value, 'siswa');
        this.kelurahan_siswa = null;
      }
    },
    'kelurahan_siswa': function () {

      if (this.kelurahan_siswa == null) {
        this.currentRecord.kelurahan = "";
      } else {

        this.currentRecord.kelurahan = this.kelurahan_siswa.nama;
      }

    },
    'provinsi_ortu': function () {
      if (this.provinsi_ortu == null) {
        this.currentRecord.provinsi_ortu = "";
      } else {
        this.currentRecord.provinsi_ortu = this.provinsi_ortu.nama;
        this.kabupaten_index(this.provinsi_ortu.value, 'ortu');
        this.kota_ortu = null;
        this.kecamatan_ortu = null;
        this.kelurahan_ortu = null;
      }

    },
    'kota_ortu': function () {
      if (this.kota_ortu == null) {
        this.currentRecord.kota_ortu = "";
      } else {
        this.currentRecord.kota_ortu = this.kota_ortu.nama;
        this.kecamatan_index(this.kota_ortu.value, 'ortu');
        this.kecamatan_ortu = null;
        this.kelurahan_ortu = null;
      }
    },
    'kecamatan_ortu': function () {

      if (this.kecamatan_ortu == null) {
        this.currentRecord.kecamatan_ortu = "";
      } else {

        this.currentRecord.kecamatan_ortu = this.kecamatan_ortu.nama;
        this.kelurahan_index(this.kecamatan_ortu.value, 'ortu');
        this.kelurahan_ortu = null;
      }

    },
    'kelurahan_ortu': function () {
      if (this.kelurahan_ortu == null) {
        this.currentRecord.kelurahan_ortu = "";
      } else {
        this.currentRecord.kelurahan_ortu = this.kelurahan_ortu.nama;
      }

    },
    'provinsi_sekolah': function () {
      if (this.provinsi_sekolah == null) {
        this.currentRecord.provinsi_sekolah = "";
      } else {
        this.currentRecord.provinsi_sekolah = this.provinsi_sekolah.nama;
        this.kabupaten_index(this.provinsi_sekolah.value, 'sekolah');
        this.kota_sekolah = null;
      }

    },
    'kota_sekolah': function () {
      if (this.kota_sekolah == null) {
        this.currentRecord.kota_sekolah = "";
      } else {
        this.currentRecord.kota_sekolah = this.kota_sekolah.nama;
      }
    },

    'currentRecord.nik_ayah': {
      handler() {
        this.currentRecord.nik_ayah = this.validateNumberString('nik_ayah');
      }
    },
    'currentRecord.nik_ibu': {
      handler() {
        this.currentRecord.nik_ibu = this.validateNumberString('nik_ibu');
      }
    },

  },
  methods: {
    validateNumberString(field) {
      // Check if the string contains only digits (0-9)
      const isValid = /^\d+$/.test(this.currentRecord[field]);
      // If the string is valid, return the string, otherwise return an empty string
      return isValid ? this.currentRecord[field] : '';
    },
    validasi_anak_ke_berapa() {
      if (this.currentRecord.anak_ke > this.currentRecord.jumlah_bersaudara) {
        AlertPopup('error', '', 'Urutan anak tidak sesuai melebihi jumlah saudara', 1500, false)
        this.currentRecord.anak_ke = 1;
      }
      if (this.currentRecord.anak_ke == 0) {
        this.currentRecord.anak_ke = 1;
      }
    },
    validateInput(field) {
      // Regular expression to check if the input contains any character or symbol
      const regex = /[^\d]/;

      if (regex.test(this.currentRecord[field])) {
        // Clear the input value if it contains a character or symbol
        this.currentRecord[field] = '';
      }
    },

    validasi_jumlah_saudara() {
      if (this.currentRecord.jumlah_bersaudara == 0) {
        this.currentRecord.jumlah_bersaudara = 1;
      }
    },


    handleInputNama(event, nama_field) {
      const input = event.target.value;
      const regex = /^[a-zA-Z\s'.,]*$/; // Regular expression to match alphabet characters, space, single quote, dot, and comma

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove the non-alphabet characters (including numbers)
        this.currentRecord[nama_field] = input.replace(/[^a-zA-Z\s'.,]/g, '');
      }
    },
    handleInputNama2(event, nama_field) {
      const input = event.target.value;
      const regex = /^[a-zA-Z\s'.,]*$/; // Regular expression to match alphabet characters, space, single quote, dot, and comma

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove the non-alphabet characters (including numbers)
        this[nama_field] = input.replace(/[^a-zA-Z\s'.,]/g, '');
      }
    },
    handleInputkodePos(event) {
      const input = event.target.value;
      const regex = /^\d*$/; // Regular expression to match only numbers

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove non-number characters
        this.currentRecord.kodepos = input.replace(/\D/g, '');
      }
    },
    handleInputkodePosOrtu(event) {
      const input = event.target.value;
      const regex = /^\d*$/; // Regular expression to match only numbers

      if (!regex.test(input)) {
        // If the input doesn't match the regex, remove non-number characters
        this.currentRecord.kodepos_ortu = input.replace(/\D/g, '');
      }
    },
    formatCurrency(event) {
      // Remove any non-digit characters
      let value = event.target.value.replace(/[^\d.]/g, "");

      // Update the data property with the formatted value
      this.currentRecord.penghasilan_ayah = rupiah2(value);
    },
    // saveData() {
    //   this.currentRecord.warga_negara = this.selectedOption;
    //   this.currentRecord.jenis_kelamin = this.selectedOption2;
    //   this.currentRecord.prodi = this.prodi;
    //   const formData = new FormData();
    //   formData.append('myfile', this.$refs.foto_pas.files[0]);
    //   localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
    //   goPage("data_akun");

    // },
    indexData() {
      if (
        Cookies.get("nama_jurusan") != "" ||
        Cookies.get("nama_jurusan") != null
      ) {
        this.prodi = Cookies.get("nama_jurusan");
        if (this.prodi == 'D3 KEBIDANAN') {
          this.isPriaDisabled = true;
          this.selectedOption2 = 'Wanita';
          this.currentRecord.jenis_kelamin = 'Wanita';
        }


      }

    },
    goPage,
    updateSelectedOption(option) {
      this.selectedOption = option;
      if (option == "WNI") {
        this.nikorpasspor = 'No Induk Kependudukan (NIK)';
        this.inputTextFeedback_ktp = 'harus 16 digit';
      } else {
        this.nikorpasspor = 'No Paspor';
        this.currentRecord.no_kk = '';
        this.inputTextFeedback_ktp = 'Wajib Diisi';
      }

      this.currentRecord.no_ktp = "";

      this.currentRecord.warga_negara = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
      this.currentRecord.jenis_kelamin = option;

      this.isMan = false;
      if (this.currentRecord.jenis_kelamin == 'Pria') {
        if (this.currentRecord.prodi_pt == 'D3 KEBIDANAN') {
          this.currentRecord.prodi_pt = "";
        }
        this.isMan = true;
      }
    },
    onOptionSelected() {
      // get the selected option value
      Cookies.set("nama_jurusan", this.prodi);
    },

    checkInputValidity(pilihan) {
      const enteredValue = this.currentRecord['' + pilihan];
      let validOptions;

      if (pilihan == 'agama') {
        validOptions = this.agamaList.map(option => option.nama);
      }

      if (pilihan == 'kota') {
        if (this.currentRecord.kota !== this.previousKota) {
          this.currentRecord.kecamatan = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan = '';
        }
        this.previousKota = this.currentRecord.kota;

        validOptions = this.kotaList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList.filter(kota => kota.nama.includes(this.currentRecord.kota));
          this.kecamatan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kecamatan') {
        if (this.currentRecord.kecamatan !== this.previousKecamatan) {
          this.currentRecord.kelurahan = '';
        }
        this.previousKecamatan = this.currentRecord.kecamatan;


        validOptions = this.kecamatanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan));
          this.kelurahan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kelurahan') {



        validOptions = this.kelurahanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }

      if (pilihan == 'provinsi_ortu') {

        if (this.currentRecord.provinsi_ortu !== this.previousProvinsiOrtu) {
          this.currentRecord.kota_ortu = ''; // Reset the value of `kota` input
          this.currentRecord.kecamatan_ortu = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousProvinsiOrtu = this.currentRecord.provinsi_ortu;

        validOptions = this.provinsiList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_ortu));
          this.kabupaten_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kota_ortu') {

        if (this.currentRecord.kota_ortu !== this.previousKotaOrtu) {
          this.currentRecord.kecamatan_ortu = ''; // Reset the value of `kabupaten` input
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousKotaOrtu = this.currentRecord.kota_ortu;

        validOptions = this.kotaList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList_ortu.filter(kota => kota.nama.includes(this.currentRecord.kota_ortu));
          this.kecamatan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kecamatan_ortu') {
        if (this.currentRecord.kecamatan_ortu !== this.previousKecamatanOrtu) {
          this.currentRecord.kelurahan_ortu = '';
        }
        this.previousKecamatanOrtu = this.currentRecord.kecamatan_ortu;
        validOptions = this.kecamatanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan_ortu));
          this.kelurahan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kelurahan_ortu') {
        validOptions = this.kelurahanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';

        }
      }

      if (pilihan == 'provinsi_sekolah') {
        if (this.currentRecord.provinsi_sekolah !== this.previousProvinsiSekolah) {
          this.currentRecord.kota_sekolah = ''; // Reset the value of `kota` input
        }
        this.previousProvinsiSekolah = this.currentRecord.provinsi_sekolah;

        validOptions = this.provinsiList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_sekolah.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_sekolah));
          this.kabupaten_index(filter[0].id, 'sekolah');
        }
      }
      if (pilihan == 'kota_sekolah') {
        validOptions = this.kotaList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }

      if (pilihan == 'prodi') {
        validOptions = this.prodiList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.prodi = '';
        }
      }


      if (pilihan == 'pendidikan_terakhir_ayah' || pilihan == 'pendidikan_terakhir_ibu') {
        validOptions = this.pendidikanTerakhirList.map(option => option.nama);
      }
      if (pilihan == 'pekerjaan_ayah' || pilihan == 'pekerjaan_ibu') {

        if (pilihan == 'pekerjaan_ayah') {

          if (this.currentRecord.pekerjaan_ayah == 'LAINNYA') {
            this.isLainnyaPekerjaanAyah = true;
          } else {
            this.isLainnyaPekerjaanAyah = false;
          }
        } else {
          if (this.currentRecord.pekerjaan_ibu == 'LAINNYA') {
            this.isLainnyaPekerjaanIbu = true;
          } else {
            this.isLainnyaPekerjaanIbu = false;
          }
        }
        validOptions = this.pekerjaanList.map(option => option.nama);
      }
      if (pilihan == 'ukuran_jas_akademis' || pilihan == 'ukuran_jas_akademis') {
        validOptions = this.ukuranJasList.map(option => option.nama);
      }


    },
    async checkValidation() {
      this.formSubmitted_1 = true;
      console.log('test');
      console.log(this.isInputTextValid('nama_lengkap'));
      if (
        this.isInputTextValid('nama_lengkap')
        && this.isInputTextValid('whatsapp')
        // && this.isInputTextValid('nama_depan')
        // && this.isInputTextValid('nama_tengah')
        // && this.isInputTextValid('nama_belakang')
        && this.isInputTextValid('email')
        && this.isInputTextValid('admin')
        && this.isInputTextValid('nama_sekolah')
        && this.isInputTextValid('jurusan_sekolah')
        && this.isInputTextValid('prodi')
        && this.isInputTextValid('waktu_kuliah')
      ) {
        this.insert();
      } else {

        await new Promise(resolve => setTimeout(resolve));
        this.scrollToInvalidInput();
      }
    },
    async insert() {
      ShowLoading();
      const formData = new FormData();
      formData.append("slug", "ustb");
      formData.append("url", "form_mandiri");
      formData.append("token", Cookies.get('token2'));


      //jenis kelamin
      this.currentRecord.jenis_kelamin = this.selectedOption2;
      this.currentRecord.warga_negara = this.selectedOption;
      this.currentRecord.prodi_pt = this.prodi;
      if (this.currentRecord.pekerjaan_ayah == "LAINNYA") {
        this.currentRecord.pekerjaan_ayah = this.isiLainnyaPekerjaanAyah;
      }
      if (this.currentRecord.pekerjaan_ibu == "LAINNYA") {
        this.currentRecord.pekerjaan_ibu = this.isiLainnyaPekerjaanIbu;
      }

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("admin");

      this.adminList.forEach((item) => {
        if (item.nama == this.currentRecord.admin) {
          formData.append("admin", item.value ?? "");

        }

      });
      // formData.append("admin", this.currentRecord.admin?.value ?? "");



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_mandiri";
      } else {
        this.api = base_url + "form_mandiri";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopupGoToURL("", "Data berhasil dimasukkan", true, response.data.link_invoice);
            // AlertPopup("", "Data berhasil dimasukkan", true, '');
            // AlertPopup("success", "", 'Data berhasil dimasukkan', 1500, false);

            // setTimeout(() => {
            // goPage('');
            // }, 1500);
            CloseLoading();
            goPage('success_registration');
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    async compressImage(file) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true
      };

      try {
        const compressedFile = await imageCompression(file, options);
        return this.blobToFile(compressedFile, compressedFile.name);
        // const imageUrl = URL.createObjectURL(compressedFile);
        // console.log(imageUrl);
        // this.imageUrl = imageUrl;
      } catch (error) {
        console.log('compression error:', error);
      }


    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageFile = file;
      this.imageUrl = URL.createObjectURL(file);
    },
    blobToFile(blob, fileName) {
      const options = {
        type: blob.type,
        lastModified: blob.lastModified
      };

      const file = new File([blob], fileName, options);
      return file;
    },
    provinsi_index() {
      axios
        .post(base_url_wilayah_indonesia + "provinsi_index", '')
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("success", response.data.message, "", 1500, false);
            this.provinsiList = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));

            this.provinsiList_ortu = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));
            this.provinsiList_sekolah = response.data.data.map((item) => ({
              nama: item.nama,
              value: item.id,
              label: item.nama,
            }));
          }
          if (response.data.status == false) {
            // AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kabupaten_index(id, jenis) {
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kotaList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              this.kotaList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "sekolah") {
              this.kotaList_sekolah = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
          }
          if (response.data.status == false) {
            //as
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kecamatan_index(id, jenis) {
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
        .then((response) => {
          if (response.data.status == true) {

            if (jenis == "ortu") {
              this.kecamatanList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              // this.kecamatanList = response.data.data;
              this.kecamatanList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
          }
          if (response.data.status == false) {
            //
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kelurahan_index(id, jenis) {

      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kelurahanList_ortu = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }
            if (jenis == "siswa") {
              this.kelurahanList = response.data.data.map((item) => ({
                nama: item.nama,
                value: item.id,
                label: item.nama,
              }));
            }

          }
          if (response.data.status == false) {
            //
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    //form input validation
    scrollToInvalidInput() {
      const invalidInput = document.querySelector('.is-invalid');
      if (invalidInput) {
        invalidInput.scrollIntoView({ block: 'center' });
        window.scrollBy(0, -100); // add 50px padding to the top
        invalidInput.focus();
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    isInputTextValid(field, page) {
      page;
      if (this.formSubmitted_1 == true) {
        if (field == 'nama_lengkap') {
          if (this.currentRecord.nama_lengkap == "") {
            return false;
          }
        }
        if (field == 'whatsapp') {
          if (this.currentRecord.whatsapp == "" || this.currentRecord.whatsapp.length < 10) {
            return false;
          }
        }
        if (field == 'email') {
          if (this.currentRecord.email == "" || !this.isValidEmail(this.currentRecord.email)) {
            return false;
          }
        }
        if (field == 'waktu_kuliah') {
          if (this.currentRecord.waktu_kuliah == "") {
            return false;
          }
        }
        // if (field == "admin") {
        //   if (this.currentRecord.admin == null) {
        //     return false;
        //   }
        // }
        if (field == 'ukuran_jas_akademis') {
          if (this.currentRecord.ukuran_jas_akademis == "") {
            return false;
          }
        }

        if (field == "prodi") {
          const indexprodi = this.prodiList.findIndex(
            (record) => record.nama === this.prodi
          )
          if (indexprodi == -1) {
            return false;
          } else {
            return true;
          }
        }

        if (field == 'waktu_kuliah') {
          if (this.currentRecord.waktu_kuliah == "") {
            return false;
          }
        }


        if (field == 'admin') {
          return true;
        } else {
          return this.currentRecord && this.currentRecord['' + field] && this.currentRecord['' + field].trim() !== '';
        }
      }
      return true;
    },
    isInputNumberValid() {
      return this.inputNumber >= 0 && this.inputNumber <= 100;
    },
    validateFileInput(field, page) {
      if (page == undefined && this.formSubmitted_1 == false) {
        return true;
      }

      if (page == 2 && this.formSubmitted_2 == false) {
        return true;
      }


      const fileInput = this.$refs[field];
      const file = fileInput.files[0];


      if (file == undefined) {
        fileInput.classList.add('is-invalid');
        return false;
      }


      const validTypes = ['image/png', 'image/gif', 'image/jpeg'];
      if (!validTypes.includes(file.type)) {
        fileInput.classList.add('is-invalid');
        return false;
      }

      fileInput.classList.remove('is-invalid');
      return true;
    },
    tahun_ajaran() {
      const formData = new FormData();
      formData.append("slug", "ustb");
      formData.append("url", "form_ta");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_ta";
      } else {
        this.api = base_url + "form_ta";
      }
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {

            this.currentRecord.tahun_ajaran = response.data.data[0].tahun_ajaran;

            this.adminList = response.data.data_marketing.map((item) => ({
              nama: item.nama_lengkap,
              value: item.id,
              label: item.nama_lengkap,
            }));


            // localStorage.setItem('tahun_ajaran', response.data.data[0].tahun_ajaran);

            // if (response.data.data[0].tanggal_mulai <= this.tanggal_acuan_hari_ini && this.tanggal_acuan_hari_ini <= response.data.data[0].tanggal_selesai) {
            //   //do nothing
            // } else {
            //   //do nothing
            //   goPage('form_pendaftaran_ditutup');
            // }
          }
          if (response.data.status == false) {
            goPage('form_pendaftaran_ditutup');
            // AlertPopup("error", response.data.message, "", false)
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    SettingCaptchaView() {

      if (this.isMobile) {
        this.Captcha = "hidden"
      }

      const badgeElement = document.querySelector('.grecaptcha-badge');
      if (this)
        if (badgeElement) {

          badgeElement.style.visibility = this.Captcha;
          badgeElement.style.top = '150px';
        } else {
          // Create a new MutationObserver
          const observer = new MutationObserver(() => {
            // When the reCAPTCHA badge element appears, hide it
            const badgeElement = document.querySelector('.grecaptcha-badge');
            if (badgeElement) {
              badgeElement.style.visibility = this.Captcha;
              badgeElement.style.top = '150px';

              // Disconnect the observer once the badge element has been hidden
              observer.disconnect();
            }
          });

          // Start observing changes to the DOM
          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        }
    },
    validateInput_number_only(event) {

      // get the key code of the pressed key
      const keyCode = event.keyCode || event.which;
      // allow digits, backspace, and delete keys
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    validateInput_number_only_no_ktp(event) {

      // get the key code of the pressed key
      const keyCode = event.keyCode || event.which;

      if (this.currentRecord.warga_negara == 'WNA') {
        // allow alphabets (A-Z and a-z) and numbers (0-9)
        if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode >= 48 && keyCode <= 57)) {
          // valid input
          return;
        }

        // prevent input for other characters
        event.preventDefault();
      } else {
        // allow digits, backspace, and delete keys
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      }

    },

    async checkFileSize(param) {
      ShowLoading();
      const file = this.$refs['' + param].files[0];

      if (file != undefined) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const fileSize = file.size;

          this.currentRecord[param] = await this.compressImage(this.$refs[param].files[0]);

          if (fileSize > 1000000) {
            AlertPopup('error', '', 'File Melebihi 1 MB, silahkan compress gambar agar dibawah 1 MB', 0, true);
            this.$refs['' + param].value = null;
          }
        };
      }
      CloseLoading();
    },

    updatePenghasilanAyah(value) {
      // Remove non-digit characters and convert to a number
      const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
      // Check if the value is a valid number
      if (!isNaN(unformattedValue)) {
        // Update the currentRecord object with the unformatted value
        this.currentRecord.penghasilan_ayah = unformattedValue;
      }
    },
    updatePenghasilanIbu(value) {
      // Remove non-digit characters and convert to a number
      const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
      // Check if the value is a valid number
      if (!isNaN(unformattedValue)) {
        // Update the currentRecord object with the unformatted value
        this.currentRecord.penghasilan_ibu = unformattedValue;
      }
    },
    validasi_ktp_nisn(tipe, value) {

      const formData = new FormData();
      formData.append("url", "validasi_ktp_nisn");
      formData.append(tipe, value);
      formData.append("slug", "ustb");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "validasi_ktp_nisn";
      } else {
        this.api = base_url + "validasi_ktp_nisn";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("error", "", response.data.message, 1500, false);

          }
          if (response.data.status == false) {
            this.currentRecord[tipe] = '';
            Swal.fire({
              title: '',
              text: response.data.message,
              showConfirmButton: true,
              allowEnterKey: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                //asdas
              }
            });
            // AlertPopup("error", "", response.data.message, 1500, false);

          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);

        });


    },
  },

  computed: {
    formattedPenghasilanAyah() {
      let angka = this.currentRecord.penghasilan_ayah || 0;
      angka = "Rp " + angka.toLocaleString("id-ID");
      angka = angka + ',-'
      return angka;

    },
    formattedPenghasilanIbu() {
      let angka = this.currentRecord.penghasilan_ibu || 0;
      angka = "Rp " + angka.toLocaleString("id-ID");
      angka = angka + ',-'
      return angka;
    },

  },

  mounted() {
    ShowLoading();
    //default value form
    this.currentRecord.penghasilan_ayah = 0;
    this.currentRecord.penghasilan_ibu = 0;
    this.currentRecord.anak_ke = 1;
    this.currentRecord.jumlah_bersaudara = 1;

    this.currentRecord.tanggal_lahir = DateNow();
    const dateNow = new Date();
    // subtract 15 years from the current year
    const year15 = new Date(dateNow.getFullYear() - 17, dateNow.getMonth(), dateNow.getDate());
    const year45 = new Date(dateNow.getFullYear() - 45, dateNow.getMonth(), dateNow.getDate());
    // set tanggal_sekarang to the updated date
    this.tanggal_sekarang = year15.toISOString().slice(0, 10);
    this.tanggal_max_lahir = year45.toISOString().slice(0, 10);
    this.tanggal_hari_ini = DateNow();
    this.currentRecord.tanggal_lahir = this.tanggal_sekarang;
    this.page_number = 1;
    this.indexData();
    this.provinsi_index();
    this.tahun_ajaran();
    CloseLoading();
    const formData = new FormData();
    formData.append("slug", "ustb");
    formData.append("url", "form_prodi");

    if (process.env.NODE_ENV === "development") {
      this.api = base_url + "form_prodi";
    } else {
      this.api = base_url + "form_prodi";
    }

    axios
      .post(this.api, formData)
      .then((response) => {
        this.prodiList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    this.SettingCaptchaView();

    this.$nextTick(() => {
      const dropdownToggle = document.querySelectorAll('.vs__dropdown-toggle');
      const searchInput = document.querySelectorAll('.vs__search');
      const focusedSearchInput = document.querySelectorAll('.vs__search:focus');
      const selectedOption = document.querySelectorAll('.vs__selected');

      dropdownToggle.forEach((toggle) => {
        toggle.style.border = 'none';
      });

      searchInput.forEach((input) => {
        input.style.fontSize = 'inherit';
        input.style.padding = '0';
      });

      focusedSearchInput.forEach((input) => {
        input.style.fontSize = 'inherit';
        input.style.padding = '0';
      });

      selectedOption.forEach((option) => {
        option.style.margin = '4px 0 0';
        option.style.padding = '0';
      });


    });

    this.tanggal_acuan_hari_ini = DateNow();

    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

  },



};
</script>

<style scoped>
.custom-select .vs__dropdown-toggle,
.custom-select .vs__open-indicator {
  border: none;
}

a {
  text-decoration: none;
}
</style>
